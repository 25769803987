.cards-wrap{
    position:relative;
}
@mixin fan($count, $angle,$spacingX,$spacingY) {
    $offset: $angle / 2;
    @for $i from 1 through $count {
      $increment: $angle / ($count + 1);
      div:nth-child(#{$i}) {
        transform: translate(-54%+(($i - 1)*($spacingX)), 10%+(($i - 1)*($spacingY))) rotate(-$offset + $increment * $i);

        //margin-top: 120px+($i*25);
        margin-top: 30px;
      }
      div:nth-child(#{$i}):hover {
        margin-top: -30px;
      }
      
    }
  }
  
  .cards {
    position: absolute;
    height:auto;
    width: 100%;
    overflow: visible;
    @include fan(8, 55deg,0,0);
    
    &:hover {
      @include fan(8, 120deg,2,10);
    }
  }
  
  .card {
    background: white;
    background-size: cover;
    height: 252px;
    width: 180px;
    border-radius: 12px;
    position: absolute;
    box-shadow: 0 0 20px transparentize(black, 0.8);
    left: 50%;

    transform-origin: center 130%;
    transition: all 400ms ease-in-out;
    //transition: transform 400ms ease-in-out;
    
    //margin-top:180px;
  }
  .card-fan-container{min-height:600px}
//   .card:hover {
//     margin-top:-50px;
//     margin-left:-40px;
//     transition: all 400ms ease-in-out;
//   }