.explainer {margin-top:30px;
}
.explainer p {
    margin:30px 30px 40px 30px;
    line-height:1.8em
}
.explainer h1 {
    margin:30px 30px 40px 30px;
    font-weight:bold;
    letter-spacing:-3px;
}


.explainer a,.explainer a:link,.explainer a:visited, .explainer a:hover {
    color:#008a25;
    text-decoration:none;

}

.explainer a:hover {
    text-decoration:underline;
}