.nakahoneypot-honeypot_collection {margin-top:30px;
}
.nakahoneypot-honeypot_collection p {
    margin:30px 30px 40px 30px;
    line-height:1.8em
}
.nakahoneypot-honeypot_collection h1 {
    margin:30px 30px 40px 30px;
    font-weight:bold;
    letter-spacing:-3px;
}


.nakahoneypot-honeypot_collection a,.nakahoneypot-honeypot_collection a:link,.nakahoneypot-honeypot_collection a:visited, .nakahoneypot-honeypot_collection a:hover {
    color:#008a25;
    text-decoration:none;

}

.nakahoneypot-honeypot_collection a:hover {
    text-decoration:underline;
}