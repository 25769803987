.vault-compare {
    margin-top: 30px;
    margin-bottom:40px;
}
.vault-compare img {max-width:100%;}
.bag-offer-note{}
.bag-offer.offer-bag{
    display:block;
    background:#198754;
    border-radius: 3px;
    padding:5px 16px;
    color:#fff;
    font-weight:bold;

}
table .bag-offer.offer-bag{
    display:inline;
    background:transparent;

    color:#424242;
    font-weight:normal;
}
.bag-offer.offer-na{
    font-size:12px;
    color:#333;
    line-height:14px;
}
.bag-man-img{
    max-width:100%;
}
.address-validation{padding:7px 14px;}
.address-validation.success{color:#0a8047}
.address-validation.invalid{color:#640621}
.form-check-box{
    display:inline;
    margin-right:16px;
}
.comparison-results{
    margin-top:40px;
}
.comparison-card {
margin-bottom:20px;
}
.comparison-card p{
    padding:0px 8px;
}

.comparison-card p .bag-offer.offer-bag {
    margin:0px -8px;
}

.form-check .form-check-input {
    float:none;
    margin-right:20px;
}
.comparison-results-table table td{
    vertical-align: top;
    text-align: left;
}
.comparison-results-table table td[align="right"]{
    vertical-align: top;
    text-align: right;
}
.comparison-results-table table td img {
    margin-bottom:8px;
    margin-right:16px;
}
.comparison-results-table table tr td{
    padding-top:6px;
}
.comparison-results-table table th{
    padding-right:16px;
}
.comparison-results-table table tr:hover {
   background:rgba(0,0,0,0.02)
}
.totals {
    font-size:1.5em;
    font-weight: bold;;
    text-align: right;
}