.address-signing-tool{
    padding:40px 0px;
    h4{
        padding-top:30px;
    }
}
.signed-message{
    width:100%;
    height:160px;
}
.verify-signatures{
    padding:20px 0px 0px 0px;
}

.copied-confirmation{

    padding:10px 0px;
    color:green;
}