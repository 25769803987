.education-details{
    padding-top:30px;
    }
    .education-details h1.heading {margin:30px 0px 40px 0px}
    .education-details p {margin:30px 0px 40px 0px; line-height: 1.8em;}
    
    .education-details a,.education-details a:link,.education-details a:visited, .education-details a:hover {
        color:#008a25;
        text-decoration:none;
    
    }
    
    .education-details a:hover {
        text-decoration:underline;
    }
