section.dispersal{
    input {width:100%;}
    .form-buttons-low{
        padding-top:50px;
        padding-bottom:50px;
    }
    .form-row{
        padding-top:12px;
        padding-bottom:16px;
    }
}
