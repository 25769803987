.marketplaces img {
    width:30px; 
    height:30px
}
.marketplaces ul {
    list-style-type: none;
    display:block;
    margin:0 14px 25px 14px;
    padding:0;

}
.marketplaces li {
    display:inline-block;
    list-style-type: none;
}
.product-img img{
    width:100%;
    max-width:100%;
    margin:0 auto;
}
.bitcoin-stamp{
    image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}
.control-bar{padding:10px 20px;margin:30px 0px}
.center{text-align: center;margin:30px}
.product-content {padding-top:20px}

.product-content p , .product-content h5,.product-content label{
    padding:0px 14px;
}
.product-content p {
    padding-bottom:0px;
    margin-bottom:6px;
}
/* .product-content p { border-bottom:1px dotted #cccccc;
    padding-bottom:10px;} */


    .product-content h5  {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width:auto;
    }


    .opening-para{
        margin-top:20px
    }