
  
  .holo-card {
    width: 400px;
    height: 560px;
    background-color: #211799;
    background-image: url(https://images.pokemontcg.io/smp/SM156.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    box-shadow: 0 0 6px 2px rgba(23, 86, 18, 0.75), 0 35px 25px -15px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: 0 auto;
    
    /* Animation ready */
    will-change: transform;
    
    transition: transform 100ms ease;
    transition-property: transform;
    
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    
    transform: translate3d(0%, 0%, -400px);
    
    &:hover {
      z-index: 2;
    }
  }
    
 
  
  .holo-card:before,
    .holo-card:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-image: linear-gradient(
        115deg,
        transparent 0%,
        rgb(0, 231, 255) 30%,
        rgb(255, 0, 231) 70%,
        transparent 100%
      );
      background-position: 0% 0%;
      background-repeat: no-repeat;
      background-size: 300% 300%;
      mix-blend-mode: color-dodge;
      opacity: 0;
      z-index: 1;
      transform: translate3d(0,0,0);
      animation: holoGradient 15s ease infinite;
    }
    .holo-card:after {
      background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/13471/sparkles.gif");
      background-position: center;
      background-size: 180%;
      mix-blend-mode: color-dodge;
      opacity: 0;
      z-index: 2;
      animation: holoSparkle 20s ease infinite;
    }
  
    .holo-card.active:before {
      opacity: 0.5;
      animation: none;
      transition: none;
      background-image: linear-gradient(
        115deg,
        transparent 0%,
        transparent 25%,
        rgb(0, 231, 255) 45%,
        rgb(255, 0, 231) 55%,
        transparent  70%,
        transparent 100%
      );
    }
  
    @keyframes holoSparkle {
      0% {
        opacity: 0;
      }
      12% {
        opacity: 1;
      }
      70% {
        opacity: 0.5;
      }
      95% {
        opacity: 0.2;
      }
    }
  
    @keyframes holoGradient {
      3% {
        opacity: 0;
      }
      5% {
        background-position: 0% 0%;
      }
      7% {
        opacity: 0.5;
      }
      9% {
        background-position: 100% 100%;
      }
      11% {
        opacity: 0;
      }
      50% {
        opacity: 0;
        background-position: 100% 100%;
      }
      55% {
        opacity: 0.3;
      }
      70% {
        opacity: 0;
        background-position: 0% 0%;
      }
    }
  

    .holo-wrapper{
        height: 550px;
        perspective-origin: 50% 50%;
        perspective: 800px;
        text-align:center;
    }
