.history-log{
    margin-top:20px;
}

.log-from, .log-to, .log-tx, .log-value {
    width:20%;
}
.log-block{width:2%;}

.log-from a, .log-to a, .log-tx a, .log-value span, .log-block span{
overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width:auto; text-decoration:none;
    }
        .history-list, .history-list-item{
            list-style-type: none;
            margin:0;
            padding:0;
        }
        .log-value{
            text-align: right;
        }
        .log-value span {
            font-weight:bold;
        }
        .log-value span.add {
            color:green;
        }
        .log-value span.minus {
            color:red;
        }

        .history-list-item{
            margin-bottom:0px;
            padding-bottom:10px;
            padding-top:10px;
            border-top:1px dotted grey;
        }
        .history-list-item-top{
            border-top:0;
        }
        .log-send-icon{width:4%}
        .history-list{margin-bottom:40px;}
        .log-block span{ color:#ccc}