.slider .col img {max-width: 100%;}
.swiper-button-prev,.swiper-button-next{
    height:80px;
    width:80px;
    background:#198754;
    border-radius: 50%; 
    text-align: center;
    line-height: 80px;
    font-size: 40px;
    position: absolute;
    z-index:92989822;
   margin-top: -90px;
    
}
.swiper-button-prev{ left:0px}
.swiper-button-next{ right:0px}
.swiper-button-prev::before{
    content:'⬅️';
    color:#fff;
    font-weight:bold;
    
}
.swiper-button-next::before{
    content:'➡️';
    color:#fff;
    font-weight:bold;
}
.slider-image{max-width:100%}
.swiper-button-disabled{opacity:0.5}