.vault-details{
padding-top:30px;
}
.vault-details h1.heading {margin:30px 0px 40px 0px}
.vault-details p {margin:30px 0px 40px 0px; line-height: 1.8em;}

.vault-details a,.vault-details a:link,.vault-details a:visited, .vault-details a:hover {
    color:#008a25;
    text-decoration:none;

}

.vault-details a:hover {
    text-decoration:underline;
}
.vault-details .swiper-button-prev,.vault-details .swiper-button-next {display:none;}