.nakahoneypot-honeypot_collectible {
    position:relative;
    margin-bottom:20px;
    padding-bottom:20px;
}
.holo-card{max-width:400px}
.nakahoneypot-honeypot_collectible .col img{ max-width:100%}
.nakahoneypot-honeypot_collectible h1,.nakahoneypot-honeypot_collectible p {margin:0}
.qr-code{
    margin-top:12px;
    max-width:125px
}
.asset-row{margin-top:20px;margin-bottom:40px;}
.asset-row h2 { margin:0}
.xchain-link{
    height:100px;
    width:100px;
    padding:0;
    margin-top: 12px;
}

.asset-row p small {color:#000;}