.logo{max-width:55px}
.logo-text{padding-left:20px;font-weight: bold;}
.nav-bar-custom a,.nav-bar-custom a:link,.nav-bar-custom a:visited,.nav-bar-custom a:hover{
    color:#fff;
    text-decoration: none;
    font-weight:bold;
}
.nav-bar-custom {margin-right:20px;}
.navbar-brand a:link,
.navbar-brand a:visited,
.navbar-brand a:hover,
.navbar-brand a
{ color:#fff; 
    text-decoration:none;}