.footer .navbar{
    padding-top:30px;
}
.footer a,.footer a:link,.footer a:visited, .footer a:hover{
    color:#fff;
    text-decoration: none;;
}
.footer a:hover{
    text-decoration: underline;
}
.footer ul {
    list-style-type: none;
    margin:0 0 30px 0;
    padding:0;
}
.footer ul li {
    padding:0px;
    margin:0px 0px 10px 0px;
}

.footer p,.footer h1,.footer h2,.footer h5,.footer h6, small {
    color:#fff;
}

.footer h1,.footer h2,.footer h5 {
    padding-top:80px;
    font-weight:bold;
    padding-bottom:20px;
}

.footer p{line-height:1.8em; margin-top:20px}

.post-new .post-content{
    margin-bottom:30px;
}
.footer .post-new p{ margin-top:0px}
.goof{margin-top:70px}

.navbar-brand a:link,
.navbar-brand a:visited,
.navbar-brand a:hover,
.navbar-brand a
{ color:#fff; 
    text-decoration:none;}